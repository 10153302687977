import React from "react";

import "./mobile-app.scss";

import QRCode from "react-qr-code";

import appImage from "../../assets/mobile-app.png";
import pMarketIcon from "../../assets/play-market.png";
import appStore from "../../assets/App-Store-Badge.png";

const MobileApp = ({text,lang}) => {
  console.log(text);

    const getText = (lang,textObj) => {
      console.log(lang)
      console.log(textObj)
      if(lang==="Рус"){
        return textObj.ru_text
      }else {
        return textObj.uz_text
      }
    }
    return (
        <section className="mobile-app-section ">
            <div className="content">
                <h4>{getText(lang,text[48])}</h4>
                <p>{getText(lang,text[49])}</p>

                <div className="images">
                    <QRCode style={{ height: "auto", maxWidth: "50%", width: "30%" }} value={"https://redirect.appmetrica.yandex.com/serve/244611662076767063"} viewBox={`0 0 256 256`} />
                    <div className="images-bottom">
                        <h6>{getText(lang,text[50])}</h6>
                        <a href="https://play.google.com/store/apps/details?id=uz.ucell.ucellmobile&hl=ru">
                            <img src={pMarketIcon} alt="" />
                        </a>
                        <a href="https://apps.apple.com/uz/app/ucell/id6444654547">
                            <img src={appStore} alt="" />
                        </a>
                    </div>
                </div>
            </div>

            <img src={appImage} alt="" />
        </section>
    );
};

export default MobileApp;
