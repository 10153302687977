import React from "react";
import "./registration.scss";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

const Registration = ({ text, lang }) => {
    let title,
        subtitle,
        title2,
        subtitle2,
        title3,
        subtitle3,
        title4,
        subtitle4 = "";

    const swiper = useSwiper();
    if (lang === "uz") {
        title = text[26]?.uz_text;
        subtitle = text[27]?.uz_text;
        title2 = text[28]?.uz_text;
        subtitle2 = text[29]?.uz_text;
        title3 = text[30]?.uz_text;
        subtitle3 = text[31]?.uz_text;
        title4 = text[32]?.uz_text;
        subtitle4 = text[33]?.uz_text;
    }
    if (lang === "Рус") {
        title = text[26]?.ru_text;
        subtitle = text[27]?.ru_text;
        title2 = text[28]?.ru_text;
        subtitle2 = text[29]?.ru_text;
        title3 = text[30]?.ru_text;
        subtitle3 = text[31]?.ru_text;
        title4 = text[32]?.ru_text;
        subtitle4 = text[33]?.ru_text;
    }
    if (lang === "en") {
        title = text[26]?.en_text;
        subtitle = text[27]?.en_text;
        title2 = text[28]?.en_text;
        subtitle2 = text[29]?.en_text;
        title3 = text[30]?.en_text;
        subtitle3 = text[31]?.en_text;
        title4 = text[32]?.en_text;
        subtitle4 = text[33]?.en_text;
    }
    if (lang === "kz") {
        title = text[26]?.kg_text;
        subtitle = text[27]?.kg_text;
        title2 = text[28]?.kg_text;
        subtitle2 = text[29]?.kg_text;
        title3 = text[30]?.kg_text;
        subtitle3 = text[31]?.kg_text;
        title4 = text[32]?.kg_text;
        subtitle4 = text[33]?.kg_text;
    }

    return (
        <section className="registration-section section company ">
            <h3 className="section__title">{title}</h3>
            <p className="section__subtitle">{subtitle}</p>

            <div className="registration-section__content">
                <Swiper
                    
                    navigation={{
                        prevEl: ".swiper-button-prev",
                        nextEl: ".swiper-button-next",
                    }}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },

                        1024: {
                            slidesPerView: 1.2,
                            spaceBetween: 30,
                        },
                    }}
                    className="registration-section__content"
                    spaceBetween={30}
                    // loop={true}
                    slidesPerView={1.2}
                >
                    <div onClick={() => swiper.slideNext()} className="swiper-button-prev"></div>

                    <SwiperSlide className="registration-section__content-item">
                        <div className="content">
                            <h3>{title2}</h3>
                            <p>{subtitle2}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="registration-section__content-item">
                        <div className="content">
                            <h3>{title3}</h3>
                            <p>{subtitle3}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="registration-section__content-item">
                        <div className="content">
                            <h3>{title4}</h3>
                            <p>{subtitle4}</p>
                        </div>
                    </SwiperSlide>
                    <div onClick={() => swiper.slidePrev()} className="swiper-button-next"></div>
                </Swiper>
            </div>
        </section>
    );
};

export default Registration;
