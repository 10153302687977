import React from "react";
import { Link } from "react-scroll";

import "./Hero.scss";
import heroImg from "../../assets/car-image.png";

const Hero = ({ load, lang, setLang, setLoad, text }) => {
    return (
        <section className="hero">
            {/* <div className="custom-container"> */}
            <div className="hero__content wow fadeInUp">
 
                <h1 className="hero__content__title">{lang === "uz" ? "Chevrolet Tracker" : "ВЫИГРАЙ"}</h1>
                <p className="hero__content__price"> {lang === "uz" ? "Yutib oling" : "Шевроле Треккер"}</p>

                <p className=" hero__content__text" data-wow-duration="0.6s" data-wow-delay="0.3s">
                    {/* {lang === 'uz' ? text[6]?.uz_text : ''} */}
                    {lang === "Рус" ? text[6]?.ru_text : ""}
                    {/* {lang === 'en' ? text[6]?.en_text : ''} */}
                    {lang === "uz" ? text[6]?.uz_text : ""}
                </p>
            </div>

            <div className="hero__buttons custom-container">
                <button className="hero__button hero__button-active">
                    <Link to="form" spy={true} smooth={true} offset={50} duration={2000}>
                        {/* {lang === 'uz' ? text[7]?.uz_text : ''} */}
                        {lang === "Рус" ? text[7]?.ru_text : ""}
                        {/* {lang === 'en' ? text[7]?.en_text : ''} */}
                        {lang === "uz" ? text[7]?.uz_text : ""}
                    </Link>
                </button>
                <button className="hero__button">
                    <Link to="company" spy={true} smooth={true} offset={50} duration={2000}>
                        {/* {lang === 'uz' ? text[8]?.uz_text : ''} */}
                        {lang === "Рус" ? text[8]?.ru_text : ""}
                        {/* {lang === 'en' ? text[8]?.en_text : ''} */}
                        {lang === "uz" ? text[8]?.uz_text : ""}
                    </Link>
                </button>
            </div>

            <div className="hero__bg">
                <picture className="hero__bg-picture">
                    <img
                        data-wow-duration="0.5s"
                        data-wow-delay="0.2s"
                        src={heroImg}
                        // width='900'
                        // height='327'
                        alt="hero-bg"
                    />
                </picture>
            </div>
            {/* </div> */}
        </section>
    );
};

export default Hero;
